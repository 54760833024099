<script setup lang="ts">
import { VMenu } from "vuetify/components/VMenu";

interface Props {
  closeOnContentClick?: boolean;
  offset?: string | number | number[];
  maxWidth?: string | number;
  // nudgeTop?: boolean; // prop is no longer available
  location?: Anchor; // previously `top` but as an option now
  disabled?: boolean;
  openOnHover?: boolean;
  openDelay?: number;
}

const props = withDefaults(defineProps<Props>(), {
  closeOnContentClick: undefined,
  offset: undefined,
  maxWidth: undefined,
  location: "bottom",
  disabled: undefined,
  openOnHover: undefined,
  openDelay: undefined,
});

const modelValue = defineModel<boolean>({
  required: false,
});
</script>

<template>
  <VMenu
    v-bind="props"
    v-model="modelValue"
  >
    <template
      v-if="$slots['activator']"
      #activator="{ props: slotProps }"
    >
      <slot
        name="activator"
        v-bind="{ props: slotProps }"
      />
    </template>
    <template
      v-if="$slots['default']"
      #default
    >
      <slot name="default" />
    </template>
  </VMenu>
</template>
