<script setup lang="ts">
// composables
const { t } = useI18n();

const invoiceStore = useInvoiceStore();
const { isLoggedIn } = useAuthStorage();
const settingsStore = useSettingsStore();
</script>

<template>
  <MChip
    v-if="isLoggedIn && settingsStore.settings"
    :color="!invoiceStore.pendingInvoices.length ? 'success' : undefined"
    :variant="invoiceStore.pendingInvoices.length ? 'outlined' : undefined"
    class="pointer-events-none"
  >
    <span v-if="!invoiceStore.pendingInvoices.length">
      {{ t("in-sync") }}
    </span>
    <span v-else>
      {{ invoiceStore.pendingInvoices.length }} {{ t("pending") }}
    </span>
  </MChip>
</template>
